import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import { LineEvent, LineThread as LibLineThread, LineThreadStatus } from 'lib';
import { Badge, Empty, Icon, Spin, Tag } from 'antd';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { formatTimestamp } from '../../../../../utils/format';
import { MessageItemWrapper } from 'components/MessageList';
import styled from 'styled-components';
import { WrappedInfiniteScroll } from '../../../../../components/WrappedInfiniteScroll';
import { Avatar } from 'components/basics/Avatar/Avatar';
import { useAtomValue } from 'jotai';
import { usersAtom } from '../../../../../atoms/firestore/user';
import { LineThread } from '../../../../../firestore/entity/line';

type StatusTagMap = {
  [K in LineThreadStatus]: {
    color: string;
    text: string;
  };
};

const statusTagMap: StatusTagMap = {
  unprocessed: {
    color: '#f50',
    text: '未対応',
  },
  processed: {
    color: '#87d068',
    text: '対応済み',
  },
};

export const LineThreads = observer((): JSX.Element => {
  const store = useStore();
  const { lineStore } = store;

  const users = useAtomValue(usersAtom);

  const { teamId, lineAccountId } = useParams<{
    teamId: string;
    lineAccountId: string;
  }>();
  const history = useHistory();

  useEffect(() => {
    lineStore.lineAccountId = lineAccountId;
    lineStore.syncLineThreads();
  }, [lineAccountId]);

  const convertEvent = (e: LineEvent) => {
    switch (e.type) {
      case 'text':
        return e.text;
      case 'sticker':
        return '（スタンプが送信されました）';
    }
  };

  const getStatusTag = (status: LineThreadStatus) => {
    if (store.selectedMessageView !== 'all') {
      return null;
    }

    const tag = statusTagMap[status];
    return (
      <Tag color={tag.color} className="!mb-1" style={{ fontSize: '10px' }}>
        {tag.text}
      </Tag>
    );
  };

  const isSelected = (thread: LibLineThread) => {
    return (
      history.location.pathname ===
      `/teams/${teamId}/lineaccounts/${lineAccountId}/${store.selectedMessageView}/${thread.id}`
    );
  };
  const loading = lineStore.threads.length === 0 && lineStore.loadingThread;

  return (
    <WrappedInfiniteScroll
      initialLoad={false}
      pageStart={0}
      loadMore={lineStore.loadMoreLineThreads}
      hasMore={lineStore.hasMoreThreads}
      useWindow={false}
      className="pt-4"
    >
      <Spin spinning={loading} indicator={<Icon type="loading" spin />}>
        {lineStore.threads.length === 0 &&
          (loading ? (
            <div style={{ height: 100 }} />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="メッセージはありません"
            />
          ))}
      </Spin>
      {lineStore.threads.map((thread) => {
        // メッセージリストの再実装が必要
        const assignee = (thread as unknown as LineThread).assignee;
        const user = users.find((u) => u.id === assignee);
        return (
          <MessageItemWrapper
            selected={isSelected(thread)}
            key={thread.id}
            onClick={() =>
              history.push(
                `/teams/${teamId}/lineaccounts/${lineAccountId}/${store.selectedMessageView}/${thread.id}`
              )
            }
          >
            <div className="py-3 pl-5 pr-7">
              {getStatusTag(thread.status)}
              <div className="mb-1 flex items-center gap-2">
                <div className="relative flex flex-1 items-center justify-between gap-2">
                  {!thread.readers.includes(store.me.id) && (
                    <div className="absolute left-[-18px] ">
                      <Unread>
                        <Badge color="blue" />
                      </Unread>
                    </div>
                  )}
                  <div className="text-sm font-bold">
                    {thread.lastLineContact.displayName}
                  </div>
                  <div className="text-xs text-sumi-600">
                    {formatTimestamp(thread.lastReceivedAt)}
                  </div>
                </div>
                {user && (
                  <Avatar
                    size={24}
                    name={user.name}
                    baseColor={user.iconBackgroundColor}
                    src={user.avatarURL}
                    showNameOnHover={false}
                    className="-mr-7"
                  />
                )}
              </div>
              <div className="overflow-hidden text-ellipsis whitespace-nowrap text-xs text-gray-400">
                {convertEvent(thread.lastLineEvent)}
              </div>
            </div>
          </MessageItemWrapper>
        );
      })}
    </WrappedInfiniteScroll>
  );
});

const Unread = styled.div`
  & > .ant-badge > .ant-badge-status-dot {
    width: 8px;
    height: 8px;
  }
`;
