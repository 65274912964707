import { DeliveryMessageCreateFormWithLogic } from '../../form/DeliveryMessageCreateForm/DeliveryMessageCreateFormWithLogic';
import { Redirect } from 'react-router-dom';
import { DELIVERY_PATHS } from '../DeliveryPage/deliveryPaths';
import { useSubscribeDocument } from '../../../../hooks/firestore/subscription';
import { companyDoc } from '../../../../firestore';
import { deliveryMessageConverter } from '../../../../firestore/entity/delivery';

type Props = {
  messageId: string | undefined;
};

export const DeliveryMessageDetailPage = ({ messageId }: Props) => {
  const [loading, message] = useSubscribeDocument(
    messageId
      ? companyDoc('deliveryMessages', messageId, deliveryMessageConverter)
      : undefined
  );
  if (!messageId) {
    return <DeliveryMessageCreateFormWithLogic messageId={undefined} />;
  }
  if (loading || !message) {
    return null;
  }
  if (message.isSending || message.isSent) {
    return <Redirect to={DELIVERY_PATHS.getStatsPath(messageId)} />;
  }
  return <DeliveryMessageCreateFormWithLogic messageId={messageId} />;
};
