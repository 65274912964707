import { twMerge } from 'tailwind-merge';
import React, { useMemo, useState } from 'react';
import { generateHtml } from 'lib';
import { handleHTML } from 'zeed-dom';
import { Dialog } from '../../../basics/dialog/Dialog';
import { DialogHeader } from '../../../basics/dialog/DialogHeader';
import SimpleBar from 'simplebar-react';
import juice from 'juice';

type Props = {
  html: string;
};

export const DeliveryMessagePreviewCard = ({ html }: Props) => {
  const generatedHtml = useMemo(() => generateHtml({ body: html }), [html]);
  const previewHtml = useMemo(() => createPreviewHtml(html), [html]);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  return (
    <>
      <div className="relative h-[252px] w-[180px]">
        <div
          className={twMerge(
            "absolute inset-0 overflow-hidden before:pointer-events-none before:absolute before:inset-0 before:z-10 before:rounded-lg  before:border before:border-sumi-200 before:content-['']"
          )}
        >
          <div
            role="img"
            className="pointer-events-none absolute left-0 top-0 w-[720px] origin-top-left scale-[0.25] select-none p-4"
            dangerouslySetInnerHTML={{ __html: previewHtml }}
          />
        </div>
        <button
          type="button"
          className="absolute inset-0 z-10 m-0 block cursor-pointer bg-transparent p-0"
          onClick={() => setPreviewDialogOpen(true)}
        />
      </div>
      <Dialog
        open={previewDialogOpen}
        onOpenChange={setPreviewDialogOpen}
        contentClassName="top-[5dvh] max-h-[90dvh]"
        width={720}
      >
        <div className="grid max-h-[90dvh] grid-rows-[auto_1fr]">
          <DialogHeader title="メール内容" />
          <SimpleBar
            className="max-h-[calc(90dvh_-_56px)]"
            classNames={{ wrapper: 'h-full' }}
          >
            <div
              className="p-4"
              dangerouslySetInnerHTML={{ __html: generatedHtml }}
            />
          </SimpleBar>
        </div>
      </Dialog>
    </>
  );
};

const createPreviewHtml = (html: string) => {
  const generated = generateHtml({ body: html });
  const styledHtml = juice(generated, {
    extraCss: 'img { user-select: none !important; }',
  });
  return handleHTML(styledHtml, (doc) => {
    doc.querySelectorAll('a').forEach((a) => {
      a._originalTagName = 'span';
      a._nodeName = 'SPAN';
    });
  });
};
