import { useMemo } from 'react';

type Props = {
  total: number;
  stats: {
    delivered: number;
    undelivered: number;
    opened: number;
    unsubscribed: number;
  } | null;
};

export const DeliveryMessageDashboard = ({ total, stats }: Props) => {
  return (
    <div className="grid grid-rows-[auto_1fr] gap-6 text-sm">
      <div className="flex items-center gap-4">
        <div className="text-[16px] font-bold">ダッシュボード</div>
        <div>
          <span>送信数 </span>
          <span className="font-bold">{total}</span>
          <span> 件</span>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="grid w-[80%] grid-cols-[1fr_1fr_1fr_1fr]">
          <Stat label="到着数" total={total} count={stats?.delivered} />
          <Stat label="開封数" total={total} count={stats?.opened} />
          <Stat label="不達数" total={total} count={stats?.undelivered} />
          <Stat label="購読解除数" total={total} count={stats?.unsubscribed} />
        </div>
      </div>
    </div>
  );
};

type StatProps = {
  total: number;
  count?: number;
  label: string;
};

const Stat = ({ total, count, label }: StatProps) => {
  const percentage = useMemo(
    () => (count != null ? Math.round((count / total) * 1000) / 10 : null),
    [count, total]
  );
  return (
    <div className="grid grid-rows-[auto_1fr_auto] items-center justify-center gap-3 text-center">
      <div>{label}</div>
      <div className="text-[20px] font-bold text-sea-500">
        {count ?? '-'} 件
      </div>
      <div>{percentage != null ? percentage : '-'} %</div>
    </div>
  );
};
