import { FC } from 'react';
import { tv } from 'tailwind-variants';

const STATUS_LABELS = {
  draft: '下書き',
  sending: '送信中',
  sent: '送信済み',
} as const;

type Status = keyof typeof STATUS_LABELS;

type Props = {
  status: Status;
};

const badge = tv({
  base: 'h-2 w-2 rounded-full',
  variants: {
    status: {
      sent: 'bg-sea-500',
      sending: 'bg-wood-400',
      draft: 'bg-sumi-400',
    },
  },
});

export const DeliveryStatus: FC<Props> = ({ status }) => {
  return (
    <div className="grid grid-cols-[auto_1fr] items-center gap-2 text-sm">
      <div className={badge({ status })} />
      <div>{STATUS_LABELS[status]}</div>
    </div>
  );
};
