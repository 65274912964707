import { DeliveryMessageStatsHeader } from '../DeliveryMessageStatsHeader/DeliveryMessageStatsHeader';
import { DeliveryMessageOverview } from '../DeliveryMessageOverview/DeliveryMessageOverview';
import { ComponentProps } from 'react';
import { DeliveryMessagePreviewCard } from '../DeliveryMessagePreviewCard/DeliveryMessagePreviewCard';
import { tv } from 'tailwind-variants';
import { DeliveryMessageDashboard } from '../DeliveryMessageDashboard/DeliveryMessageDashboard';
import { DeliveryMessageDetailTable } from '../DeliveryMessageDetailTable/DeliveryMessageDetailTable';

type Props = {
  overview: ComponentProps<typeof DeliveryMessageOverview>;
  html: string;
  dashboard: ComponentProps<typeof DeliveryMessageDashboard>;
  detail: ComponentProps<typeof DeliveryMessageDetailTable>;
};

const divider = tv({
  base: 'my-4 h-[1px] w-full bg-sumi-300',
});

export const DeliveryMessageStatsPage = ({
  overview,
  html,
  dashboard,
  detail,
}: Props) => {
  return (
    <div className="flex flex-col gap-4 pb-16">
      <DeliveryMessageStatsHeader />
      <div className="grid grid-cols-[1fr_auto] gap-4">
        <DeliveryMessageOverview {...overview} />
        <DeliveryMessagePreviewCard html={html} />
      </div>
      <div className={divider()}></div>
      <DeliveryMessageDashboard {...dashboard} />
      <div className={divider()}></div>
      <DeliveryMessageDetailTable {...detail} />
    </div>
  );
};
