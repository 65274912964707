import { SettingPageDrawer } from '../../../common/SettingPageDrawer/SettingPageDrawer';
import { Button } from '../../../../../components/basics';
import { InputGroup } from '../../../../../components/forms/InputGroup/InputGroup';
import { Input } from '../../../../../components/forms';
import React, { useMemo, useState } from 'react';
import { validateEmail } from '../../../../../validate';
import { debounce } from 'lodash';
import { InboxOAuthButtons } from '../InboxOAuthButtons/InboxOAuthButtons';

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onAddByEmailAddress: (email: string) => Promise<void>;
  onAddByGoogleOauth: () => Promise<void>;
  onAddByMicrosoftOauth: () => Promise<void>;
  googleOauthDisabled?: boolean;
  container?: HTMLElement;
};

export const EmailAddDrawer = ({
  open,
  onOpenChange,
  onAddByEmailAddress,
  onAddByGoogleOauth,
  onAddByMicrosoftOauth,
  googleOauthDisabled,
  container,
}: Props) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleAddByEmail = async () => {
    if (error) {
      return;
    }
    try {
      setLoading(true);
      await onAddByEmailAddress(email);
      setEmail('');
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const handleAddByGoogle = async () => {
    try {
      setLoading(true);
      await onAddByGoogleOauth();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const handleAddByMicrosoft = async () => {
    try {
      setLoading(true);
      await onAddByMicrosoftOauth();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const validate = useMemo(
    () =>
      debounce((email: string) => {
        if (validateEmail(email)) {
          setValid(true);
          setError(false);
        } else {
          setValid(false);
          setError(true);
        }
      }, 200),
    []
  );
  const onChangeEmail = (email: string) => {
    setEmail(email);
    if (email.length <= 0) {
      setValid(false);
      setError(false);
      return;
    }
    validate(email);
  };
  return (
    <SettingPageDrawer
      title="メールアドレスを追加"
      open={open}
      onOpenChange={onOpenChange}
      container={container}
    >
      <div className="flex flex-col gap-4">
        <InboxOAuthButtons
          type={googleOauthDisabled ? 'outlook' : 'all'}
          onClick={async (type) => {
            switch (type) {
              case 'google':
                await handleAddByGoogle();
                return;
              case 'outlook':
                await handleAddByMicrosoft();
                return;
            }
          }}
          readonly={loading}
        />
        <InputGroup
          label="もしくはチームで共有したいメールアドレスを入力"
          description="例） support@example.com, contact@example.com, sales@example.com"
          errorMessage={
            error ? '正しいメールアドレスを入力してください' : undefined
          }
        >
          <Input
            value={email}
            onChange={(e) => onChangeEmail(e.target.value)}
            placeholder="support@example.com"
            disabled={loading}
          />
        </InputGroup>
        <div>
          <Button
            loading={loading}
            disabled={!valid}
            onClick={() => handleAddByEmail()}
          >
            作成
          </Button>
        </div>
      </div>
    </SettingPageDrawer>
  );
};
