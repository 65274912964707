import { ReactNode } from 'react';
import { SettingPageContainer } from '../../../../App/Settings/common/SettingPageContainer/SettingPageContainer';
import { Button, Icon } from '../../../basics';
import { Alert } from '../../../basics/Alert/Alert';
import { Check } from '../../../icons';

type Props = {
  onCreateMessage: () => void;
  children: ReactNode;
  readonly?: boolean;
  noUpgradeHint?: boolean;
};

export const DeliveryMessagesPage = ({
  onCreateMessage,
  children,
  readonly = false,
  noUpgradeHint = false,
}: Props) => {
  return (
    <SettingPageContainer title="メール一斉送信" full>
      <div className="mb-4 flex flex-col gap-4 text-sm">
        {!noUpgradeHint && (
          <Alert>
            無料でお試しいただけますが、署面に「yaritoriから送信」が入ります。有料でのご利用を希望の方は、営業担当までご連絡ください。
          </Alert>
        )}
        {[
          '大量のメールを一斉に送信できます。メルマガ・お知らせなどを遅滞なく効率的に送信することができます。',
          '生成AIによりメール文の作成ができます。今後はAI機能のアップデートや分析機能などを充実させていきます。',
        ].map((message, i) => (
          <div key={i} className="grid grid-cols-[auto_1fr] items-center gap-2">
            <Icon icon={Check} size={24} className="text-sea-500" />
            <div>{message}</div>
          </div>
        ))}
        <Button
          className="self-start"
          onClick={() => onCreateMessage()}
          disabled={readonly}
        >
          メールを作成
        </Button>
        <div className="max-w-full">{children}</div>
      </div>
    </SettingPageContainer>
  );
};
