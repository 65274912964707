import { Button, Select } from 'antd';
import React from 'react';
import { serverTimestamp, updateDoc } from 'firebase/firestore';
import { SPBackButton } from '../Conversations/Conversation/SPBackButton';
import { useStore } from '../../../hooks/useStore';
import {
  LineAccount,
  LineContact,
  LineThread,
  LineThreadStatus,
} from '../../../firestore/entity/line';
import { useAtomValue } from 'jotai';
import { teamMembersFamily } from '../../../atoms/firestore/team';
import Avatar from '../../Common/Avatar';
import { usersAtom } from '../../../atoms/firestore/user';
import { getAssigneeOptions } from '../Conversations/Conversation/Header/util';

type Props = {
  lineAccount: LineAccount;
  lineThread: LineThread;
  lineContacts: LineContact[];
};

export const LineConversationHeader = ({
  lineAccount,
  lineThread,
  lineContacts,
}: Props): JSX.Element => {
  const store = useStore();

  const updateAssignee = (assignee: string) => {
    updateDoc(lineThread.ref, {
      assignee: assignee || null,
      updatedAt: serverTimestamp(),
    });
  };

  const users = useAtomValue(usersAtom);
  const teamMembers = useAtomValue(teamMembersFamily(lineAccount.teamId));

  const assigneeOptions = getAssigneeOptions(users, teamMembers);

  const updateStatusToProcessed = async () => {
    if (!lineThread) {
      return;
    }
    await updateDoc(lineThread.ref, {
      status: LineThreadStatus.Processed,
      updatedAt: serverTimestamp(),
    });
  };

  const disabled = lineThread.status === LineThreadStatus.Processed;

  return (
    <div className="flex flex-col gap-1 bg-sumi-50 p-4">
      <SPBackButton
        backLink={`/teams/${lineAccount.teamId}/lineaccounts/${lineAccount.id}/${store.selectedMessageView}`}
      />
      <div className="text-sm">{lineAccount.name}</div>
      <div className="flex items-center justify-between">
        <div className="text-lg font-bold">
          {lineContacts.map((c) => c.displayName).join(', ')}
        </div>
        <div className="flex gap-2">
          <Select
            value={lineThread.assignee ?? ''}
            style={{ minWidth: 160 }}
            onChange={(value) => updateAssignee(value)}
            disabled={disabled}
          >
            <Select.OptGroup label="担当者">
              {assigneeOptions.map((o) =>
                o.user ? (
                  <Select.Option
                    value={o.user.id}
                    key={o.user.id}
                    disabled={!o.isTeamMember}
                  >
                    <Avatar
                      user={o.user}
                      size="small"
                      style={{ marginTop: -3 }}
                    />
                    <span className={`ml-[4px]`}>
                      {o.isTeamMember ? null : '(チーム外)'}
                      {o.user.name}
                    </span>
                  </Select.Option>
                ) : (
                  <Select.Option key="none" value="">
                    担当者未設定
                  </Select.Option>
                )
              )}
            </Select.OptGroup>
          </Select>
          <div>
            <Button
              icon="check"
              type={
                lineThread.status === LineThreadStatus.Unprocessed
                  ? 'dashed'
                  : 'default'
              }
              onClick={updateStatusToProcessed}
              disabled={disabled}
            >
              {lineThread.status === LineThreadStatus.Unprocessed
                ? '対応済みにする'
                : '対応済み'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
