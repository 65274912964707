import React, { useMemo } from 'react';
import { Message } from 'lib';
import Attachments from './attachments';
import { Loading } from '../../../components/basics';
import parse from 'html-react-parser';
import { sanitizeHtml } from 'lib';
import { tv } from 'tailwind-variants';

type Props = {
  message: Message;
  loading: boolean;
  attachments: any;
  html: string | null;
};

const text = tv({
  base: 'mb-0.5 text-xs',
});

export const PrintMessageBody: React.FC<Props> = ({
  message,
  loading,
  attachments,
  html,
}) => {
  const sanitizedHtml = useMemo(() => {
    if (loading) return null;
    if (!html) return null;
    return parse(sanitizeHtml(html));
  }, [loading, html]);

  return (
    <div className="break-words">
      {loading && <Loading />}
      {!loading && (
        <>
          <div className="mb-3 flex justify-between">
            <div>
              <div className="flex">
                <p className={text()}>{message.fromText}</p>
              </div>
              {message.replyAddress &&
                message.replyAddress !== message.fromText && (
                  <div className="flex">
                    <p className={text()}>返信先:</p>
                    <p className={text()}>{message.replyAddress}</p>
                  </div>
                )}
              {message.to && (
                <div className="flex">
                  <p className={text()}>To:</p>
                  {message.toAddresses.map((address) => (
                    <p className={text()} key={address}>{`　${address}`}</p>
                  ))}
                </div>
              )}
              {message.cc && (
                <div className="flex">
                  <p className={text()}>Cc:</p>
                  {message.ccAddresses.map((address) => (
                    <p className={text()} key={address}>{`　${address}`}</p>
                  ))}
                </div>
              )}
              {message.bcc && (
                <div className="flex">
                  <p className={text()}>Bcc:</p>
                  {message.bccAddresses.map((address) => (
                    <p className={text()} key={address}>{`　${address}`}</p>
                  ))}
                </div>
              )}
            </div>
            <div>
              <p className={text()}>
                {message.date.format('YYYY年M月D日 HH:mm')}
              </p>
            </div>
          </div>
          <div className="print:scrollbar-none mb-5 text-xs sm:mb-3 print:[&_*::-webkit-scrollbar]:hidden">
            <Attachments attachments={attachments} />
            {/* 
              廃止したcssプロパティが一部のメーラーが混入させてくるため、
              App.css の message-print-body で廃止したcssを無効化
             */}
            <div className="message-print-body">{sanitizedHtml}</div>
          </div>
        </>
      )}
    </div>
  );
};
