import { DeliveryMessageStatsHeader } from '../DeliveryMessageStatsHeader/DeliveryMessageStatsHeader';
import React from 'react';
import { InputGroup } from '../../../forms/InputGroup/InputGroup';
import { DeliveryMessageDashboard } from '../DeliveryMessageDashboard/DeliveryMessageDashboard';
import { range } from 'lodash';
import { tv } from 'tailwind-variants';

const skeleton = tv({
  base: 'animate-pulse rounded-lg bg-sumi-200',
});

export const DeliveryMessageStatsPagePlaceholder = () => {
  return (
    <div className="flex flex-col gap-4 pb-16">
      <DeliveryMessageStatsHeader />
      <div className="grid grid-cols-[1fr_auto] gap-4">
        <div className="flex flex-col gap-4 text-sm">
          <div className="text-[16px] font-bold">メール概要</div>
          <InputGroup label="送信元アドレス">
            <div className={skeleton({ className: 'h-5 w-1/2' })} />
          </InputGroup>
          <InputGroup label="宛先">
            <div className="flex gap-2">
              <div className={skeleton({ className: 'h-6 w-12' })} />
              <div className={skeleton({ className: 'h-6 w-20' })} />
            </div>
          </InputGroup>
          <InputGroup label="件名">
            <div className={skeleton({ className: 'h-5 w-1/2' })} />
          </InputGroup>
          <InputGroup label="ステータス">
            <div className={skeleton({ className: 'h-5 w-32' })} />
          </InputGroup>
          <InputGroup label="送信日時">
            <div className={skeleton({ className: 'h-5 w-40' })} />
          </InputGroup>
        </div>
        <div className={skeleton({ className: 'h-[252px] w-[180px]' })} />
      </div>
      <div className="my-4 h-[1px] w-full bg-sumi-300" />
      <DeliveryMessageDashboard total={0} stats={null} />
      <div className="my-4 h-[1px] w-full bg-sumi-300" />
      <div className="flex flex-col items-start gap-4 text-sm">
        <div className="text-[16px] font-bold">詳細</div>
        <div className="flex w-full flex-col gap-1">
          {range(8).map((i) => (
            <div key={i} className={skeleton({ className: 'h-6 w-full' })} />
          ))}
        </div>
      </div>
    </div>
  );
};
