import { TagColor } from 'lib';
import { InputGroup } from '../../../forms/InputGroup/InputGroup';
import React from 'react';
import { Tooltip } from '../../../basics/Tooltip/Tooltip';
import { Tag } from '../../../basics/Tag/Tag';
import { Icon } from '../../../basics';
import { Attach } from '../../../icons';
import bytes from 'bytes';
import moment from 'moment/moment';
import { DeliveryStatus } from '../../list/DeliveryStatus/DeliveryStatus';

type Props = {
  address: {
    name: string | null;
    email: string;
  };
  tags: {
    team: string;
    name: string;
    color: TagColor | null;
  }[];
  subject: string;
  attachments: {
    name: string;
    size: number;
  }[];
  status: 'sending' | 'sent';
  sentAt?: Date;
};

export const DeliveryMessageOverview = ({
  address,
  tags,
  subject,
  attachments,
  status,
  sentAt,
}: Props) => {
  return (
    <div className="flex flex-col gap-4 text-sm">
      <div className="text-[16px] font-bold">メール概要</div>
      <InputGroup label="送信元アドレス">
        <>
          {address.name ? `${address.name} <${address.email}>` : address.email}
        </>
      </InputGroup>
      <InputGroup label="宛先">
        <div className="flex gap-2">
          {tags.map((tag, i) => (
            <Tooltip key={i} content={tag.team}>
              <Tag color={tag.color} size="sm">
                {tag.name}
              </Tag>
            </Tooltip>
          ))}
        </div>
      </InputGroup>
      <InputGroup label="件名">
        <>{subject}</>
      </InputGroup>
      {attachments.length > 0 && (
        <InputGroup label="添付ファイル">
          <div className="flex flex-col gap-1.5">
            {attachments.map((attachment, i) => (
              <div
                key={i}
                className="grid grid-cols-[auto_1fr] items-center justify-start"
              >
                <div className="mr-1 flex h-5 w-5 items-center justify-center">
                  <Icon icon={Attach} size={20} />
                </div>
                <span className="truncate">
                  {attachment.name} ({bytes.format(attachment.size)})
                </span>
              </div>
            ))}
          </div>
        </InputGroup>
      )}
      <InputGroup label="ステータス">
        <DeliveryStatus status={status} />
      </InputGroup>
      <InputGroup label="送信日時">
        <>{sentAt ? moment(sentAt).format('YYYY年M月D日 HH:mm') : '-'}</>
      </InputGroup>
    </div>
  );
};
