import * as Popover from '@radix-ui/react-popover';
import * as Toolbar from '@radix-ui/react-toolbar';
import React from 'react';
import { range } from 'lodash';
import { tv } from 'tailwind-variants';

type Props = {
  iconRender: (color: string) => JSX.Element;
  defaultColor: string;
  placeholderColor?: string;
  color: string | undefined;
  setColor: (color: string | undefined) => void;
  disabled?: boolean;
};

const PALETTE = [
  ['#000000', '#ffffff', '#bbbbbb', '#888888', '#444444'],
  ['#e60000', '#facccc', '#f06666', '#a10000', '#5c0000'],
  ['#ff9900', '#ffebcc', '#ffc266', '#b26b00', '#663d00'],
  ['#ffff00', '#ffffcc', '#ffff66', '#b2b200', '#666600'],
  ['#008a00', '#cce8cc', '#66b966', '#006100', '#003700'],
  ['#0066cc', '#cce0f5', '#66a3e0', '#0047b2', '#002966'],
  ['#9933ff', '#ebd6ff', '#c285ff', '#6b24b2', '#3d1466'],
] as const;

const paletteItem = tv({
  base: 'flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-current p-0',
  variants: {
    active: {
      true: 'border-[2px] border-[color:color-mix(in_srgb,currentColor_80%,black)]',
      false: 'border border-[color:color-mix(in_srgb,currentColor_90%,black)]',
    },
  },
});

export const ToolbarColorPalette = ({
  iconRender,
  defaultColor,
  placeholderColor = 'currentColor',
  color = defaultColor,
  setColor,
  disabled,
}: Props) => {
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Toolbar.Button
          className="flex h-7 cursor-pointer select-none items-center justify-between rounded-lg border border-sumi-300 bg-transparent px-2 text-sm text-sumi-900 focus-visible:outline-1 focus-visible:outline-sea-500/50 enabled:hover:bg-sumi-50 disabled:cursor-not-allowed disabled:text-sumi-300"
          disabled={disabled}
        >
          <span className="block h-5 w-5">
            {iconRender(
              disabled || color === defaultColor ? placeholderColor : color
            )}
          </span>
        </Toolbar.Button>
      </Popover.Trigger>
      <Popover.Content
        className="z-50 flex flex-col gap-2.5 rounded-lg bg-white p-2.5 shadow-dropdown"
        collisionPadding={16}
      >
        <div className="grid grid-cols-7 gap-2 overflow-hidden">
          {range(0, 5).map((y) =>
            range(0, 7).map((x) => {
              const itemColor = PALETTE[x][y];
              const active = color === itemColor;
              return (
                <button
                  type="button"
                  key={itemColor}
                  className={paletteItem({ active })}
                  style={{ color: itemColor }}
                  onClick={() =>
                    setColor(itemColor === defaultColor ? undefined : itemColor)
                  }
                >
                  {active && (
                    <span className="block h-2 w-2 rounded-full border border-sumi-200 bg-white/90" />
                  )}
                </button>
              );
            })
          )}
        </div>
        <div className="flex items-center justify-end">
          <button
            type="button"
            className="cursor-pointer bg-transparent p-0 text-xs hover:underline"
            onClick={() => setColor(undefined)}
          >
            リセット
          </button>
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};
