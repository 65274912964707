import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { DeliveryMessageStatsPage } from './DeliveryMessageStatsPage';
import { useAtom, useAtomValue } from 'jotai';
import { deliveryEventsFamily } from '../../../../atoms/firestore/deliveryMessages';
import {
  DeliveryEventData,
  DeliveryMessage,
} from '../../../../firestore/entity/delivery';
import { DeliveryMessageDetailTable } from '../DeliveryMessageDetailTable/DeliveryMessageDetailTable';
import { observer } from 'mobx-react';
import { useStore } from '../../../../hooks/useStore';
import { LRUCache } from 'lru-cache';
import { uniq } from 'lodash';
import { Paginate } from '../../../../utils/atom';
import { companyAtom } from '../../../../atoms/auth';

type Props = {
  message: DeliveryMessage;
};

type Filter = ComponentProps<typeof DeliveryMessageDetailTable>['filter'];

export const DeliveryMessageStatsPageWithLogic: FC<Props> = observer(
  ({ message }) => {
    const status = useMemo(() => {
      if (message.isSent) {
        return 'sent';
      }
      return 'sending';
    }, [message.isSent]);

    const detailProps = useDetailProps(message);

    return (
      <DeliveryMessageStatsPage
        overview={{
          subject: message.subject,
          address: message.display?.address ?? { name: null, email: '' },
          tags:
            message.display?.contactTags?.map((tag) => ({
              team: tag.teamName,
              name: tag.name,
              color: tag.color,
            })) ?? [],
          sentAt: message.sentAt?.toDate(),
          status,
          attachments: message.attachments.map((a) => ({
            name: a.name,
            size: a.size,
          })),
        }}
        html={message.html ?? message.text}
        dashboard={{
          total: message.display?.recipients?.count ?? 0,
          stats: message.stats
            ? {
                opened: message.stats.openedCount,
                undelivered: message.stats.undeliveredCount,
                delivered: message.stats.deliveredCount,
                unsubscribed: message.stats.unsubscribedCount,
              }
            : null,
        }}
        detail={detailProps}
      />
    );
  }
);

type DetailProps = ComponentProps<typeof DeliveryMessageStatsPage>['detail'];

type CacheKey = {
  teamId: string;
  email: string;
};

export const useDetailProps = (
  message: DeliveryMessage
): ComponentProps<typeof DeliveryMessageStatsPage>['detail'] => {
  const company = useAtomValue(companyAtom);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState<Filter>({
    status: null,
    pageSize: 10,
  });

  const eventsAtom = useMemo(
    () =>
      deliveryEventsFamily({
        deliveryId: message.id,
        status: filter.status,
        pageSize: filter.pageSize,
      }),
    [message.id, filter]
  );
  const [events, dispatch] = useAtom(eventsAtom);
  const teamIds = useMemo(
    () => uniq(message.display?.contactTags.map((t) => t.teamId)) ?? [],
    [message.display]
  );
  const pageEntries = usePageEntries(events, page, filter.pageSize, teamIds);

  const onPageChange = (dir: number) => {
    setPage((page) => {
      const newPage = page + dir;

      const total = filter.pageSize * (newPage + 1);
      if (events.state === 'hasData' && events.data.length < total) {
        dispatch('loadMore').then();
      }
      return newPage;
    });
  };

  const onFilterChange = (filter: Filter) => {
    setFilter(filter);
    setPage(0);
  };

  const listItemTotalCount = useMemo(() => {
    if (!message.stats) {
      return 0;
    }
    return message.stats.deliveredCount + message.stats.undeliveredCount;
  }, [message.stats]);

  return {
    freemium: company.deliveryFeaturesUpgraded !== true,
    skeletonCount: Math.min(filter.pageSize, listItemTotalCount),
    filter,
    onFilterChange,
    data: pageEntries,
    onPageChange,
    hasPreviousPage: page > 0,
    hasNextPage:
      events.state === 'hasData' &&
      (events.hasMore || (page + 1) * filter.pageSize < events.data.length),
  };
};

type ContactData = { name: string; tel: string };

const contactCache = new LRUCache<CacheKey, ContactData>({
  max: 256,
});

const usePageEntries = (
  paginate: Paginate<DeliveryEventData>,
  page: number,
  pageSize: number,
  teamIds: string[]
): DetailProps['data'] => {
  const store = useStore();
  const [data, setData] = useState<DetailProps['data']>([]);

  useEffect(() => {
    if (paginate.state !== 'hasData') {
      return;
    }
    if (paginate.data.length <= page * pageSize) {
      return;
    }
    const events = paginate.data.slice(
      page * pageSize,
      page * pageSize + pageSize
    );

    const promises = events.map(async (event) => {
      const promises = uniq(teamIds).map(async (teamId) => {
        const key: CacheKey = { teamId, email: event.email };
        if (contactCache.has(key)) {
          return contactCache.get(key)!;
        }
        const contact = await store.contactStore.getContactByEmail(teamId, {
          address: event.email,
        });
        const data: ContactData = {
          name: contact.name,
          tel: contact.phoneNumber,
        };
        contactCache.set(key, data);
        return data || null;
      });

      const names = (await Promise.all(promises)).filter(
        (name): name is ContactData => name != null
      );
      return [event.email, names.length > 0 ? names : null] as [
        string,
        ContactData[],
      ];
    });
    Promise.all(promises)
      .then((r) => Object.fromEntries(r))
      .then((r) => {
        const data: DetailProps['data'] = events.map((event) => ({
          name: r[event.email].map((d) => d.name),
          email: event.email,
          status: event.status,
          openedAt: event.openedAt?.toDate() ?? null,
          tel: r[event.email].map((d) => d.tel),
        }));
        setData(data);
      });
  }, [paginate, page, pageSize]);

  return data;
};
