import { PageWithMainNav } from '../../../../App/Common/MainNav';
import React, { useEffect, useMemo } from 'react';
import { DeliveryMessageStatsPageWithLogic } from '../../stats/DeliveryMessageStatsPage/DeliveryMessageStatsPageWithLogic';
import SimpleBar from 'simplebar-react';
import { useHistory, useParams } from 'react-router-dom';
import { DELIVERY_PATHS } from '../DeliveryPage/deliveryPaths';
import { DeliveryMessageStatsPagePlaceholder } from '../../stats/DeliveryMessageStatsPagePlaceholder/DeliveryMessageStatsPagePlaceholder';
import { companyDoc } from '../../../../firestore';
import { deliveryMessageConverter } from '../../../../firestore/entity/delivery';
import { useSubscribeDocument } from '../../../../hooks/firestore/subscription';

export const DeliveryStatsPage = () => {
  const { messageId } = useParams<{ messageId: string }>();
  const history = useHistory();
  const [loading, message] = useSubscribeDocument(
    companyDoc('deliveryMessages', messageId, deliveryMessageConverter)
  );

  useEffect(() => {
    if (!loading && !message) {
      history.push(DELIVERY_PATHS.getIndexPath());
    }
  }, [history, loading, message]);

  const content = useMemo(() => {
    if (message) {
      return <DeliveryMessageStatsPageWithLogic message={message} />;
    }
    return <DeliveryMessageStatsPagePlaceholder />;
  }, [message]);

  return (
    <PageWithMainNav>
      <SimpleBar className="h-[calc(100dvh_-_theme(height.header))]">
        <div className="flex justify-center px-4">
          <div className="w-[1100px] max-w-full">{content}</div>
        </div>
      </SimpleBar>
    </PageWithMainNav>
  );
};
