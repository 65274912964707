import { observer } from 'mobx-react';
import {
  ColumnFiltersState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Checkbox } from '../../../components/forms';
import { tv } from 'tailwind-variants';
import { Button, Icon, IconButton, Loading } from '../../../components/basics';
import { TagColor } from 'lib';
import { useMemo, useState } from 'react';
import { Tag } from '../../../components/basics/Tag/Tag';
import { CaretDown, Filter } from '../../../components/icons';
import * as Popover from '@radix-ui/react-popover';
import { useConfirmDialog } from '../../../hooks/confirmDialog';
import { ContactTagsInput } from '../Team/ContactTagsInput/ContactTagsInput';
import { compact, intersection, uniqBy } from 'lodash';
import { AccountMenuButton } from './AccountMenuButton';
import { twMerge } from 'tailwind-merge';
import SimpleBar from 'simplebar-react';
import { TablePagination } from '../../../components/table/TablePagination/TablePagination';

type ContactTag = {
  id: string;
  name: string;
  color: TagColor | null | undefined;
};

type Props = {
  contacts: Contact[];
  tags: ContactTag[];
  accounts: {
    id: string;
    name: string;
  }[];
  onOpenDrawer: (id: string) => void;
  onCreateMessage: (
    contacts: Contact[],
    target: 'to' | 'cc' | 'bcc'
  ) => PromiseLike<void>;
  onDelete: (contacts: Contact[]) => Promise<void>;
  onAddTag: (contacts: Contact[], tag: Omit<ContactTag, 'id'>) => void;
  onRemoveTag: (contacts: Contact[], tagId: string) => void;
  onEditTag: (tagId: string, tag: Omit<ContactTag, 'id'>) => void;
  pageSize: number;
  onChangePageSize: (size: number) => void;
  page: number;
  onChangePage: (page: number) => void;
  hasMore: boolean;
  sort: { column: string; dir: 'asc' | 'desc' } | undefined;
  onChangeSort: (
    sort: { column: string; dir: 'asc' | 'desc' } | undefined
  ) => void;
  onSetAccount: (ids: string[], accountId: string) => void;
  onUnsetAccount: (ids: string[]) => void;
  onChangeTagFilter: (tagNames: string[]) => void;
  searching: boolean;
};

type Contact = {
  id: string;
  teamId: string;
  name: string;
  accountId?: string | undefined;
  email: string;
  tags: string[];
  companyName: string;
  phoneNumber: string;
  status: 'loading' | 'available' | 'unavailable' | 'partial';
  memo: string;
};

const NO_WRAP_COLS = ['name', 'status', 'phoneNumber', 'companyName'];

const th = tv({
  base: 'select-none text-sm font-normal',
  variants: {
    nowrap: {
      true: 'whitespace-nowrap',
    },
  },
});

const tr = tv({
  base: 'h-10 border-b border-sumi-200',
});

const td = tv({
  base: 'break-all px-1',
  variants: {
    nowrap: {
      true: 'whitespace-nowrap',
    },
  },
});

const menu = tv({
  base: 'min-w-[var(--radix-popover-trigger-width)] rounded bg-white py-1 text-xs shadow-dropdown',
});

const menuButton = tv({
  base: 'block h-8 w-full cursor-pointer bg-transparent px-4 text-start hover:bg-sumi-100',
});

const columnHelper = createColumnHelper<Contact>();

export const ContactsDetail = observer(
  ({
    contacts,
    tags,
    accounts,
    onOpenDrawer,
    onCreateMessage,
    onDelete,
    onAddTag,
    onRemoveTag,
    onEditTag,
    pageSize,
    onChangePageSize,
    page,
    onChangePage,
    hasMore,
    sort,
    onChangeSort,
    onSetAccount,
    onUnsetAccount,
    onChangeTagFilter,
    searching,
  }: Props) => {
    const showDialog = useConfirmDialog();
    const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [draftCreating, setDraftCreating] = useState(false);
    const [filterTags, setFilterTags] = useState<string[]>([]);
    const columns = useMemo(
      () => [
        columnHelper.accessor('name', {
          header: '名前',
        }),
        columnHelper.accessor('accountId', {
          header: '取引先',
          cell: (info) => {
            const id = info.getValue();
            if (!id) {
              return '';
            }
            return accounts.find((a) => a.id === id)?.name ?? id;
          },
        }),
        columnHelper.accessor('email', {
          header: 'メールアドレス',
        }),
        columnHelper.accessor('tags', {
          header: 'タグ',
          cell: (info) => {
            const elements = info
              .getValue()
              .map((id) => tags.find((t) => t.id === id))
              .filter((t) => t)
              .map((t) => t as ContactTag)
              .map((tag, i) => (
                <Tag
                  key={i}
                  color={tag.color}
                  className="h-6 border-none text-xs"
                >
                  {tag.name}
                </Tag>
              ));
            return <div className="flex flex-wrap gap-1">{elements}</div>;
          },
          enableSorting: false,
        }),
        columnHelper.accessor('companyName', {
          header: '会社名',
        }),
        columnHelper.accessor('phoneNumber', {
          header: '電話番号',
        }),
        columnHelper.accessor('status', {
          header: '状態',
          cell: (info) => {
            switch (info.getValue()) {
              case 'loading':
                return <Loading size={16} />;
              case 'available':
                return '配信可能';
              case 'unavailable':
                return '配信停止';
              case 'partial':
                return '一部配信';
            }
          },
        }),
        columnHelper.accessor('memo', {
          header: 'メモ',
        }),
      ],
      [tags]
    );

    const table = useReactTable<Contact>({
      data: contacts,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      onColumnFiltersChange: setColumnFilters,
      state: {
        columnFilters,
        pagination: {
          pageSize: pageSize,
          pageIndex: 0,
        },
      },
    });

    const selectedCount = selectedContacts.length;
    const selectedIds = selectedContacts.map((c) => c.id);

    const tagsInput = useMemo(() => {
      const intersectedTags = intersection(
        ...contacts.filter((c) => selectedIds.includes(c.id)).map((c) => c.tags)
      );
      const value = compact(
        intersectedTags.map((tagId) => tags.find((t) => t.id === tagId))
      );
      return (
        <ContactTagsInput
          tags={tags}
          value={value}
          onChange={(newValue) => {
            const addedTag = newValue.find(
              (t) => !value.some((v) => v.name === t.name)
            );
            const removedTag = value.find(
              (t) => !newValue.some((v) => v.name === t.name)
            );
            const editedTag = newValue.find((t) =>
              value.some((v) => v.name === t.name && v.color !== t.color)
            );
            const editedTagId = tags.find((t) => t.name === editedTag?.name)
              ?.id;
            if (addedTag) {
              const filteredContacts = contacts
                .filter((c) => selectedIds.includes(c.id))
                .filter(
                  (c) =>
                    !c.tags
                      .map((t) => tags.find((tag) => tag.id === t))
                      .some((t) => t?.name === addedTag.name)
                );
              onAddTag(filteredContacts, addedTag);
            }
            if (removedTag) {
              onRemoveTag(selectedContacts, removedTag.id);
            }
            if (editedTagId && editedTag) {
              onEditTag(editedTagId, editedTag);
            }
          }}
        />
      );
    }, [contacts, tags, selectedIds]);

    const handleCreateMessage: Props['onCreateMessage'] = async (
      contacts,
      target
    ) => {
      if (draftCreating) {
        return;
      }

      setDraftCreating(true);
      try {
        await onCreateMessage(contacts, target);
      } catch (e) {
        console.error(e);
      } finally {
        setDraftCreating(false);
      }
    };

    const toggleAllSelect = () => {
      setSelectedContacts(
        contacts.every((c) => selectedIds.includes(c.id))
          ? selectedContacts.filter(
              (contact) => !contacts.some((c) => c.id === contact.id)
            )
          : uniqBy([...selectedContacts, ...contacts], (c) => c.id)
      );
    };

    const toggleSelect = (contact: Contact) => {
      selectedIds.includes(contact.id)
        ? setSelectedContacts(
            selectedContacts.filter((c) => c.id !== contact.id)
          )
        : setSelectedContacts(
            uniqBy([...selectedContacts, contact], (c) => c.id)
          );
    };

    return (
      <div
        className={twMerge(
          'relative flex flex-col gap-2',
          searching ? 'pointer-events-none' : ''
        )}
      >
        <div className="text-normal font-bold">
          {selectedCount > 0
            ? `${selectedCount}件のコンタクトを選択中`
            : `${contacts.length}件のコンタクト`}
        </div>
        {selectedCount > 0 && (
          <div className="flex gap-2">
            <Popover.Root>
              <Popover.Trigger asChild>
                <Button
                  size="sm"
                  color="sumi"
                  variant="outlined"
                  className="grid grid-cols-[1fr_auto] items-center gap-1.5 border-sumi-300 font-normal text-sumi-900"
                >
                  <span>メールを作成する</span>
                  <Icon icon={CaretDown} size={20} />
                </Button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content className={menu()}>
                  <button
                    type="button"
                    className={menuButton()}
                    onClick={() => handleCreateMessage(selectedContacts, 'to')}
                    disabled={draftCreating}
                  >
                    宛先に設定
                  </button>
                  <button
                    type="button"
                    className={menuButton()}
                    onClick={() => handleCreateMessage(selectedContacts, 'cc')}
                    disabled={draftCreating}
                  >
                    Ccに設定
                  </button>
                  <button
                    type="button"
                    className={menuButton()}
                    onClick={() => handleCreateMessage(selectedContacts, 'bcc')}
                    disabled={draftCreating}
                  >
                    Bccに設定
                  </button>
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
            <AccountMenuButton
              accounts={accounts}
              onChange={(id) => onSetAccount(selectedIds, id)}
              onRemove={() => onUnsetAccount(selectedIds)}
            />
            <Popover.Root>
              <Popover.Trigger asChild>
                <Button
                  size="sm"
                  color="sumi"
                  variant="outlined"
                  className="grid grid-cols-[1fr_auto] items-center gap-1.5 border-sumi-300 font-normal text-sumi-900"
                >
                  <span>タグを設定</span>
                  <Icon icon={CaretDown} size={20} />
                </Button>
              </Popover.Trigger>
              <Popover.Portal>
                <Popover.Content className="w-[250px] rounded-lg shadow-dropdown">
                  {tagsInput}
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
            <Button
              color="danger"
              variant="outlined"
              size="sm"
              onClick={() =>
                showDialog({
                  title: `${selectedCount}件のコンタクトを削除しますか？`,
                  description: '一度削除すると元に戻せません',
                  okType: 'danger',
                  okText: '削除',
                  onOk: () => onDelete(selectedContacts),
                })
              }
            >
              削除
            </Button>
          </div>
        )}
        <SimpleBar>
          <table className="w-full min-w-[1000px] table-auto">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className={tr()}>
                  <th className={th({ className: 'h-9 w-9' })}>
                    <div className="flex w-6 items-center justify-center">
                      <Checkbox
                        onChange={() => toggleAllSelect()}
                        checked={contacts.every((c) =>
                          selectedIds.includes(c.id)
                        )}
                        indeterminate={contacts.some((c) =>
                          selectedIds.includes(c.id)
                        )}
                      />
                    </div>
                  </th>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className={th({ nowrap: header.column.id !== 'memo' })}
                    >
                      <div className="flex items-center justify-between">
                        <div
                          className="flex gap-1"
                          onClick={() => {
                            if (searching) {
                              return;
                            }
                            if (sort) {
                              if (sort.dir === 'asc') {
                                onChangeSort({
                                  column: header.column.id,
                                  dir: 'desc',
                                });
                              } else {
                                onChangeSort(undefined);
                              }
                            } else {
                              onChangeSort({
                                column: header.column.id,
                                dir: 'asc',
                              });
                            }
                          }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          {!['accountId', 'status'].includes(
                            header.column.id
                          ) && (
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5 7.61904L10 2.85714L15 7.61904H5Z"
                                fill="currentColor"
                                className={
                                  sort?.column === header.column.id &&
                                  sort.dir === 'asc'
                                    ? 'text-sea-500'
                                    : 'text-sumi-300'
                                }
                              />
                              <path
                                d="M15 12.3809L10 17.1429L5 12.3809L15 12.3809Z"
                                fill="currentColor"
                                className={
                                  sort?.column === header.column.id &&
                                  sort.dir === 'desc'
                                    ? 'text-sea-500'
                                    : 'text-sumi-300'
                                }
                              />
                            </svg>
                          )}
                        </div>
                        {header.column.id === 'tags' && (
                          <Popover.Root
                            onOpenChange={(open) => {
                              if (open) {
                                return;
                              }
                              onChangeTagFilter(filterTags);
                            }}
                          >
                            <Popover.Trigger asChild>
                              <IconButton component={Filter} className="mr-2" />
                            </Popover.Trigger>
                            <Popover.Portal>
                              <Popover.Content
                                className={menu({
                                  className: 'p-0',
                                })}
                              >
                                <SimpleBar className="max-h-[250px] min-w-[150px]">
                                  <div className="flex flex-col gap-2 p-2">
                                    {tags.map((tag, i) => {
                                      return (
                                        <button
                                          key={i}
                                          className="m-0 grid cursor-pointer grid-cols-[auto_1fr] items-center gap-1.5 bg-transparent p-0"
                                          onClick={() =>
                                            filterTags.includes(tag.name)
                                              ? setFilterTags(
                                                  filterTags.filter(
                                                    (t) => t !== tag.name
                                                  )
                                                )
                                              : setFilterTags([
                                                  ...filterTags,
                                                  tag.name,
                                                ])
                                          }
                                        >
                                          <Checkbox
                                            checked={filterTags.includes(
                                              tag.name
                                            )}
                                            tabIndex={-1}
                                            readOnly
                                          />
                                          <Tag
                                            color={tag.color}
                                            size="sm"
                                            className="whitespace-nowrap"
                                          >
                                            {tag.name}
                                          </Tag>
                                        </button>
                                      );
                                    })}
                                  </div>
                                </SimpleBar>
                                <div className="flex items-center justify-between border-t border-sumi-300 px-2 py-1 text-sm">
                                  <button
                                    type="button"
                                    className="m-0 cursor-pointer bg-transparent p-0 text-sumi-500"
                                    onClick={() => {
                                      setFilterTags([]);
                                      onChangeTagFilter([]);
                                    }}
                                  >
                                    クリア
                                  </button>
                                  <button
                                    type="button"
                                    className="m-0 cursor-pointer bg-transparent p-0 text-sea-500"
                                    onClick={() =>
                                      onChangeTagFilter(filterTags)
                                    }
                                  >
                                    適用
                                  </button>
                                </div>
                              </Popover.Content>
                            </Popover.Portal>
                          </Popover.Root>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className={tr({ className: 'hover:bg-sumi-50' })}
                  onClick={() => onOpenDrawer(row.original.id)}
                >
                  <td className="h-9 w-9">
                    <div className="flex w-6 items-center justify-center">
                      <Checkbox
                        checked={selectedIds.includes(row.original.id)}
                        onChange={() => toggleSelect(row.original)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </div>
                  </td>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={td({
                        nowrap: NO_WRAP_COLS.includes(cell.column.id),
                      })}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </SimpleBar>
        <div className="flex justify-end pb-4 pt-6">
          <TablePagination
            pageSize={pageSize}
            onPageSizeChange={onChangePageSize}
            sizes={[25, 50, 100]}
            onPageChange={(dir) => onChangePage(page + dir)}
            hasPrevious={page > 1}
            hasNext={hasMore}
          />
        </div>
        {searching && (
          <div className="absolute inset-0 left-0 top-0 flex items-center justify-center bg-white/50 text-sumi-700">
            <Loading size={24} />
          </div>
        )}
      </div>
    );
  }
);
