import { companyCollection } from '../../firestore';
import { orderBy, query, QuerySnapshot, where } from 'firebase/firestore';
import {
  deliveryEventConverter,
  DeliveryEventData,
  DeliveryMessage,
  deliveryMessageConverter,
} from '../../firestore/entity/delivery';
import {
  atomFamilyWithLRU,
  atomWithPaginate,
  Paginate,
  PaginateAtom,
} from '../../utils/atom';
import { store } from '../../providers/StoreProvider';
import { isEqual } from 'lodash';
import { ColumnSort } from '@tanstack/table-core/src/features/RowSorting';

type DeliveryMessageFilter = {
  sorting: ColumnSort | null;
  pageSize: number;
};

const SORTING_COLUMNS: string[] = ['subject', 'updatedAt', 'sentAt'];

export const deliveryMessagesFamily = atomFamilyWithLRU<
  DeliveryMessageFilter,
  Paginate<DeliveryMessage>,
  PaginateAtom<DeliveryMessage>
>({
  initializeAtom: (filter) => {
    let q = query(
      companyCollection(`deliveryMessages`, deliveryMessageConverter)
    );

    if (filter.sorting && SORTING_COLUMNS.includes(filter.sorting.id)) {
      q = query(
        q,
        orderBy(filter.sorting.id, filter.sorting.desc ? 'desc' : 'asc')
      );
    } else {
      q = query(q, orderBy('createdAt', 'desc'));
    }

    return atomWithPaginate<DeliveryMessage>(
      q,
      simpleMerge('id'),
      filter.pageSize
    );
  },
  max: 20,
  maxSize: 200,
  sizeCalculator: (value) => {
    if (value.state !== 'hasData') {
      return 0;
    }
    return value.data.length;
  },
  dispose: (anAtom) => store.set(anAtom, 'unsubscribe'),
  areEqual: isEqual,
});

type DeliveryEventFilter = {
  deliveryId: string;
  status: DeliveryEventData['status'] | null;
  pageSize: number;
};

export const deliveryEventsFamily = atomFamilyWithLRU<
  DeliveryEventFilter,
  Paginate<DeliveryEventData>,
  PaginateAtom<DeliveryEventData>
>({
  initializeAtom: (filter) => {
    let q = query(
      companyCollection(
        `deliveryMessages/${filter.deliveryId}/events`,
        deliveryEventConverter
      ),
      orderBy('updatedAt', 'desc')
    );

    if (filter.status) {
      q = query(q, where('status', '==', filter.status));
    }

    return atomWithPaginate<DeliveryEventData>(
      q,
      simpleMerge('email'),
      filter.pageSize
    );
  },
  max: 20,
  maxSize: 200,
  sizeCalculator: (value) => {
    if (value.state !== 'hasData') {
      return 0;
    }
    return value.data.length;
  },
  dispose: (anAtom) => store.set(anAtom, 'unsubscribe'),
  areEqual: isEqual,
});

export const simpleMerge = <
  T,
  K extends string,
  KT extends { [P in K]: string } & T,
>(
  key: K
): ((snapshot: QuerySnapshot<KT>, prev: KT[]) => KT[]) => {
  return (snapshot, prev) => {
    for (const change of snapshot.docChanges()) {
      const data = change.doc.data();
      switch (change.type) {
        case 'added':
          prev.push(data);
          break;
        case 'modified':
          prev.splice(
            prev.findIndex((e) => e[key] === data[key]),
            1,
            data
          );
          break;
        case 'removed':
          prev.splice(
            prev.findIndex((e) => e[key] === data[key]),
            1
          );
          break;
      }
    }
    return prev;
  };
};
