import { atom } from 'jotai';
import {
  atomWithSubscribeCollection,
  companyCollection,
} from '../../firestore';
import { teamConverter } from 'lib';
import { meAtom } from '../auth';
import { orderBy, query, where } from 'firebase/firestore';
import { store } from '../../providers/StoreProvider';
import { atomFamily } from 'jotai/utils';
import { usersAtom } from './user';

export const [teamsLoadingAtom, teamsAtom, teamAtomsAtom] =
  atomWithSubscribeCollection(
    () => query(companyCollection('teams', teamConverter), orderBy('name')),
    (snapshot) =>
      snapshot.docs
        .map((doc) => doc.data())
        .filter((team) => team.isPrivate === false)
  );

export const joinedTeamsAtom = atom((get) => {
  const me = get(meAtom);
  const teams = get(teamsAtom).filter((team) => team.isMember(me.id));
  const privateTeamArray = get(privateTeamArrayAtom);
  if (privateTeamArray.length) {
    teams.unshift(privateTeamArray[0]);
  }
  return teams;
});

export const joinedTeamIdsAtom = atom((get) =>
  get(joinedTeamsAtom).map((team) => team.id)
);

export const [privateTeamLoadingAtom, privateTeamArrayAtom] =
  atomWithSubscribeCollection(
    () => {
      const me = store.get(meAtom);
      return query(
        companyCollection('teams', teamConverter),
        where(`roles.${me.id}`, '==', 'owner')
      );
    },
    (snapshot) =>
      snapshot.docs.map((doc) => doc.data()).filter((team) => team.isPrivate)
  );

export const privateTeamAtom = atom((get) => {
  const arr = get(privateTeamArrayAtom);
  if (!arr.length) {
    throw new Error('private team not loaded.');
  }
  return arr[0];
});

export const teamFamily = atomFamily((teamId: string) => {
  return atom((get) => get(teamsAtom).find((team) => team.id === teamId));
});

export const teamMembersFamily = atomFamily((teamId: string) => {
  return atom((get) => {
    const team = get(teamFamily(teamId));
    if (!team) {
      throw new Error('Team not found.');
    }
    return get(usersAtom).filter((user) => team.isMember(user.id));
  });
});
