import { ComponentProps } from 'react';

type Props = ComponentProps<'svg'> & {
  dir: 'asc' | 'desc' | null;
};

export const SortArrows = ({ dir, ...props }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0002 5.2999L9.1502 8.1499C8.99803 8.2999 8.81415 8.3749 8.59857 8.3749C8.38299 8.3749 8.2002 8.29904 8.0502 8.14733C7.9002 7.99559 7.8252 7.81226 7.8252 7.59733C7.8252 7.38238 7.9002 7.1999 8.0502 7.0499L11.4752 3.6249C11.5585 3.54157 11.643 3.48324 11.7285 3.4499C11.8141 3.41657 11.9058 3.3999 12.0035 3.3999C12.1013 3.3999 12.1919 3.41657 12.2752 3.4499C12.3585 3.48324 12.4419 3.54157 12.5252 3.6249L15.9502 7.0499C16.1002 7.20207 16.1752 7.38594 16.1752 7.60153C16.1752 7.81711 16.0993 7.9999 15.9476 8.1499C15.7959 8.2999 15.6126 8.3749 15.3976 8.3749C15.1827 8.3749 15.0002 8.2999 14.8502 8.1499L12.0002 5.2999Z"
        fill="currentColor"
        className={dir === 'desc' ? 'opacity-25' : ''}
      />
      <path
        d="M14.8502 15.9499L12.0002 18.7999L9.1502 15.9499C9.0002 15.7999 8.81772 15.7249 8.60277 15.7249C8.38784 15.7249 8.2045 15.7999 8.05277 15.9499C7.90105 16.0999 7.8252 16.2827 7.8252 16.4983C7.8252 16.7139 7.9002 16.8977 8.0502 17.0499L11.4752 20.4749C11.5585 20.5582 11.6419 20.6166 11.7252 20.6499C11.8085 20.6832 11.8991 20.6999 11.9969 20.6999C12.0946 20.6999 12.1863 20.6832 12.2719 20.6499C12.3574 20.6166 12.4419 20.5582 12.5252 20.4749L15.9502 17.0499C16.1002 16.8999 16.1752 16.7174 16.1752 16.5025C16.1752 16.2875 16.1002 16.1042 15.9502 15.9525C15.8002 15.8008 15.6174 15.7249 15.4018 15.7249C15.1862 15.7249 15.0024 15.7999 14.8502 15.9499Z"
        fill="currentColor"
        className={dir === 'asc' ? 'opacity-25' : ''}
      />
    </svg>
  );
};
