export const DELIVERY_PATHS = {
  index: '/delivery',
  new: '/delivery/new',
  edit: '/delivery/:messageId',
  stats: '/delivery/:messageId/stats',
  getIndexPath: () => DELIVERY_PATHS.index,
  getNewPath: () => DELIVERY_PATHS.new,
  getEditPath: (messageId: string) => `/delivery/${messageId}`,
  getStatsPath: (messageId: string) => `/delivery/${messageId}/stats`,
} as const;
