import { Select } from '../../forms';
import { IconButton } from '../../basics';
import { CaretLeft, CaretRight } from '../../icons';
import { ComponentPropsWithoutRef } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = Omit<ComponentPropsWithoutRef<'div'>, 'children'> & {
  pageSize: number;
  onPageSizeChange: (pageSize: number) => void;
  sizes: number[];
  onPageChange: (direction: number) => void;
  hasPrevious: boolean;
  hasNext: boolean;
};

export const TablePagination = ({
  pageSize,
  onPageSizeChange,
  onPageChange,
  sizes,
  hasPrevious,
  hasNext,
  className,
  ...props
}: Props) => {
  return (
    <div className={twMerge('flex w-fit gap-2', className)} {...props}>
      <Select
        value={pageSize}
        options={sizes.map((size) => ({
          value: size,
          label: `ページあたり${size}件`,
        }))}
        onChange={(v: number) => onPageSizeChange(v)}
        variants={{
          rounded: 'lg',
        }}
      />
      <IconButton
        component={CaretLeft}
        color="sumi"
        size="lg"
        onClick={() => onPageChange(-1)}
        disabled={!hasPrevious}
        aria-label="前のページに戻る"
      />
      <IconButton
        component={CaretRight}
        color="sumi"
        size="lg"
        onClick={() => onPageChange(1)}
        disabled={!hasNext}
        aria-label="次のページに進む"
      />
    </div>
  );
};
