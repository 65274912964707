import { PageWithMainNav } from '../../../../App/Common/MainNav';
import { DeliveryMessagesPageWithLogic } from '../../list/DeliveryMessagesPage/DeliveryMessagesPageWithLogic';
import { Route, Switch } from 'react-router-dom';
import React from 'react';
import { DeliveryMessageDetailPage } from '../DeliveryMessageDetailPage/DeliveryMessageDetailPage';
import SimpleBar from 'simplebar-react';

export const DeliveryPage = () => {
  return (
    <>
      <PageWithMainNav>
        <SimpleBar className="h-[calc(100dvh_-_theme(height.header))]">
          <DeliveryMessagesPageWithLogic />
        </SimpleBar>
      </PageWithMainNav>
      <Switch>
        <Route
          exact
          path="/delivery/new"
          render={() => <DeliveryMessageDetailPage messageId={undefined} />}
        />
        <Route
          exact
          path="/delivery/:messageId"
          render={({ match }) => (
            <DeliveryMessageDetailPage messageId={match.params.messageId} />
          )}
        />
      </Switch>
    </>
  );
};
