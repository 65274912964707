import { DeliveryMessagesPage } from './DeliveryMessagesPage';
import { useAtomValue } from 'jotai';
import { companyAtom, meAtom } from '../../../../atoms/auth';
import { useHistory } from 'react-router-dom';
import { DELIVERY_PATHS } from '../../page/DeliveryPage/deliveryPaths';
import {
  ComponentProps,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { deleteDoc, doc } from 'firebase/firestore';
import { companyCollection } from '../../../../firestore';
import { DeliveryMessageIndexTable } from '../DeliveryMessageIndexTable/DeliveryMessageIndexTable';
import { ColumnSort } from '@tanstack/table-core/src/features/RowSorting';
import {
  DeliveryMessage,
  DeliveryMessageData,
} from '../../../../firestore/entity/delivery';
import { deliveryMessagesFamily } from '../../../../atoms/firestore/deliveryMessages';
import { useAtom } from 'jotai/index';
import { Paginate } from '../../../../utils/atom';

export const DeliveryMessagesPageWithLogic = () => {
  const me = useAtomValue(meAtom);
  const history = useHistory();

  const company = useAtomValue(companyAtom);
  const [sorting, setSorting] = useState<ColumnSort | null>(null);
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const messagesAtom = useMemo(
    () =>
      deliveryMessagesFamily({
        sorting,
        pageSize,
      }),
    [sorting, pageSize]
  );
  const [messages, dispatch] = useAtom(messagesAtom);
  const data = useData(messages, page, pageSize);

  const onCreateMessage = () => {
    history.push(DELIVERY_PATHS.getNewPath());
  };

  const onPageChange = (dir: number) => {
    setPage((page) => {
      const newPage = page + dir;

      const total = pageSize * (newPage + 1);
      if (messages.state === 'hasData' && messages.data.length < total) {
        dispatch('loadMore').then();
      }
      return newPage;
    });
  };

  const onMessageDelete = async (messageId: string) => {
    const ref = doc(companyCollection('deliveryMessages'), messageId);
    deleteDoc(ref).then();
  };

  return (
    <DeliveryMessagesPage
      onCreateMessage={onCreateMessage}
      readonly={me.isReadOnly}
      noUpgradeHint={company.deliveryFeaturesUpgraded}
    >
      <DeliveryMessageIndexTable
        data={data}
        sorting={sorting}
        onSortingChange={setSorting}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        onPageChange={onPageChange}
        hasPreviousPage={page > 0}
        hasNextPage={
          messages.state === 'hasData' &&
          (messages.hasMore || (page + 1) * pageSize < messages.data.length)
        }
        onMessageDelete={onMessageDelete}
        loading={data.length === 0}
      />
    </DeliveryMessagesPage>
  );
};

type Data = ComponentProps<typeof DeliveryMessageIndexTable>['data'];

const useData = (
  paginate: Paginate<DeliveryMessage>,
  page: number,
  pageSize: number
): Data => {
  const getData = useCallback((): Data | null => {
    if (paginate.state !== 'hasData') {
      return null;
    }
    if (paginate.data.length <= page * pageSize) {
      return null;
    }

    const sliced = paginate.data.slice(
      page * pageSize,
      page * pageSize + pageSize
    );

    return sliced.map((m) => ({
      id: m.id,
      subject: m.subject,
      status: getMessageStatus(m),
      updatedAt: m.updatedAt.toDate(),
      sentAt: m.sentAt?.toDate() ?? null,
    }));
  }, [paginate, page, pageSize]);

  const [data, setData] = useState<Data>(getData() ?? []);

  useLayoutEffect(() => {
    const newData = getData();
    if (newData) {
      setData(newData);
    }
  }, [getData]);

  return data;
};

type Status = ComponentProps<
  typeof DeliveryMessageIndexTable
>['data'][number]['status'];

const getMessageStatus = (message: DeliveryMessageData): Status => {
  if (message.isSent) {
    return 'sent';
  }
  if (message.isSending) {
    return 'sending';
  }
  return 'draft';
};
