import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ComponentProps, useCallback, useMemo } from 'react';
import moment from 'moment/moment';
import { DeliveryStatus } from '../DeliveryStatus/DeliveryStatus';
import SimpleBar from 'simplebar-react';
import { twMerge } from 'tailwind-merge';
import { SortArrows } from '../../../table/SortArrows/SortArrows';
import { ColumnSort } from '@tanstack/table-core/src/features/RowSorting';
import { Icon } from '../../../basics';
import { Delete } from '../../../icons';
import { Tooltip } from '../../../basics/Tooltip/Tooltip';
import { Link, useHistory } from 'react-router-dom';
import { DELIVERY_PATHS } from '../../page/DeliveryPage/deliveryPaths';
import { TablePagination } from '../../../table/TablePagination/TablePagination';
import { range } from 'lodash';
import { useConfirmDialog } from '../../../../hooks/confirmDialog';

type Status = ComponentProps<typeof DeliveryStatus>['status'];

type Entry = {
  id: string;
  subject: string;
  status: Status;
  updatedAt: Date;
  sentAt: Date | null;
};

type Props = {
  data: Entry[];
  sorting: ColumnSort | null;
  onSortingChange: (sorting: ColumnSort | null) => void;
  pageSize: number;
  onPageSizeChange: (pageSize: number) => void;
  onPageChange: (direction: number) => void;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  onMessageDelete: (messageId: string) => Promise<void>;
  loading: boolean;
};

export const DeliveryMessageIndexTable = ({
  data,
  sorting,
  onSortingChange,
  pageSize,
  onPageSizeChange,
  onPageChange,
  hasPreviousPage,
  hasNextPage,
  onMessageDelete,
  loading,
}: Props) => {
  const history = useHistory();
  const showDialog = useConfirmDialog();
  const columns: ColumnDef<Entry>[] = useMemo(() => {
    return [
      {
        accessorKey: 'subject',
        cell: (info) => info.getValue(),
        header: '件名',
      },
      {
        accessorKey: 'status',
        enableSorting: false,
        cell: (info) => {
          const status = info.getValue() as Status;
          return <DeliveryStatus status={status} />;
        },
        header: 'ステータス',
      },
      {
        accessorKey: 'updatedAt',
        cell: (info) => {
          const value = info.getValue() as Date;
          return moment(value).format('YYYY年M月D日 HH:mm');
        },
        header: '最終更新日時',
      },
      {
        accessorKey: 'sentAt',
        cell: (info) => {
          const value = info.getValue() as Date | null;
          if (!value) {
            return '-';
          }
          return moment(value).format('YYYY年M月D日 HH:mm');
        },
        header: '送信日時',
      },
    ];
  }, []);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    state: {
      sorting: sorting ? [sorting] : [],
    },
    onSortingChange: (updated) =>
      typeof updated === 'function'
        ? onSortingChange(updated(sorting ? [sorting] : []).at(0) ?? null)
        : onSortingChange(updated.at(0) ?? null),
  });

  const onClickRow = useCallback(
    (e: MouseEvent, entry: Entry) => {
      const target = e.target;
      if (!target || !(target instanceof Element)) {
        return;
      }

      // 件名部分のクリックはaタグに任せるので無効化
      if (target instanceof HTMLAnchorElement) {
        return;
      }

      // 削除ボタン部分は無効化
      if (target.closest('button')) {
        return;
      }

      history.push(getEntryPath(entry));
    },
    [history]
  );

  return (
    <div className="flex flex-col">
      <SimpleBar className="w-full" autoHide={false}>
        <table className="w-full max-w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b border-b-sumi-300">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={twMerge(
                        'px-2 py-1 font-normal',
                        header.column.getCanSort() ? 'cursor-pointer' : ''
                      )}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.isPlaceholder ? null : (
                        <div className="grid h-8 select-none grid-cols-[auto_1fr] items-center gap-2 whitespace-nowrap">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {header.column.getCanSort() && (
                            <SortArrows
                              dir={header.column.getIsSorted() || null}
                              className="h-[20px] w-[20px]"
                            />
                          )}
                        </div>
                      )}
                    </th>
                  );
                })}
                <th />
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="group cursor-pointer border-b border-b-sumi-300 last:border-b-0 hover:bg-sumi-50"
                  onClick={(e) => onClickRow(e.nativeEvent, row.original)}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className="h-9 whitespace-nowrap p-0">
                        {cell.column.id === 'subject' ? (
                          <Link
                            to={getEntryPath(row.original)}
                            className="flex h-full w-full min-w-[300px] items-center whitespace-normal px-2 py-1 text-black"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Link>
                        ) : (
                          <div className="px-2 py-1">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </div>
                        )}
                      </td>
                    );
                  })}
                  <td className="w-9 p-0">
                    {row.original.status === 'draft' && (
                      <div className="flex items-start justify-center">
                        <Tooltip content="下書きを削除">
                          <button
                            type="button"
                            className="invisible m-0 flex h-7 w-7 cursor-pointer items-center justify-center rounded bg-transparent p-0 text-red-500 hover:bg-red-500/10 group-hover:visible"
                            aria-label="下書きを削除"
                            onClick={() => {
                              showDialog({
                                title: '下書きを削除しますか？',
                                okText: '削除',
                                okType: 'danger',
                                onOk: () => onMessageDelete(row.original.id),
                              });
                            }}
                          >
                            <Icon icon={Delete} size={20} />
                          </button>
                        </Tooltip>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </SimpleBar>
      {!loading && data.length === 0 && (
        <div className="flex h-24 w-full items-center justify-center">
          <span className="select-none text-sumi-700">データがありません</span>
        </div>
      )}
      {loading && data.length === 0 && (
        <div className="flex flex-col">
          {range(6).map((i) => (
            <div key={i} className="flex h-9 items-end">
              <div className="h-6 w-full animate-pulse rounded bg-sumi-100" />
            </div>
          ))}
        </div>
      )}
      <div className="mt-4 flex w-full justify-end">
        <TablePagination
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          sizes={[25, 50, 100]}
          onPageChange={onPageChange}
          hasPrevious={hasPreviousPage}
          hasNext={hasNextPage}
        />
      </div>
    </div>
  );
};

const getEntryPath = (entry: Entry) => {
  return entry.status === 'draft'
    ? DELIVERY_PATHS.getEditPath(entry.id)
    : DELIVERY_PATHS.getStatsPath(entry.id);
};
